"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNricValid = void 0;
/**
 * Validates whether a provided string value adheres to the UIN/FIN format
 * as provided on the Singapore Government's National Registration Identity Card.
 * @param value The value to be validated
 */
var isNricValid = function (value) {
    return isFormatValid(value) && isChecksumValid(value);
};
exports.isNricValid = isNricValid;
/**
 * Tests whether a provided string value obeys a simple format check
 * @param value The value to be validated
 */
var isFormatValid = function (value) {
    return /^([STFGstfg]{1})([0-9]{7})([A-Za-z]{1})$/.test(value);
};
/**
 * Algorithm to test whether the NRIC checksum is valid
 * @param value The value to be validated
 */
var isChecksumValid = function (value) {
    // http://www.ngiam.net/NRIC/NRIC_numbers.pdf
    value = value.toUpperCase();
    var prefix = value.charAt(0);
    var suffix = value.charAt(value.length - 1);
    var coefficients = [2, 7, 6, 5, 4, 3, 2];
    var constant = prefix === 'S' || prefix === 'F' ? 0 : 4;
    var coding = prefix === 'S' || prefix === 'T'
        ? ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A']
        : ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
    var sum = value
        .substring(1, value.length - 1)
        .split('')
        .reduce(function (sum, digit, idx) {
        sum += parseInt(digit) * coefficients[idx];
        return sum;
    }, constant);
    return suffix === coding[sum % 11];
};
