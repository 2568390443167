"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIELDS_TO_REJECT = exports.types = void 0;
var types_1 = require("../../../types");
exports.types = [
    {
        name: types_1.BasicField.Section,
        value: 'Header',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Statement,
        value: 'Statement',
        submitted: false,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Email,
        value: 'Email',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Mobile,
        value: 'Mobile Number',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.HomeNo,
        value: 'Home Number',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Number,
        value: 'Number',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Decimal,
        value: 'Decimal',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Image,
        value: 'Image',
        submitted: false,
        answerArray: false,
    },
    {
        name: types_1.BasicField.ShortText,
        value: 'Short Text',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.LongText,
        value: 'Long Text',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Dropdown,
        value: 'Dropdown',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.YesNo,
        value: 'Yes/No',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Checkbox,
        value: 'Checkbox',
        submitted: true,
        answerArray: true,
    },
    {
        name: types_1.BasicField.Radio,
        value: 'Radio',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Attachment,
        value: 'Attachment',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Date,
        value: 'Date',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Rating,
        value: 'Rating',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Nric,
        value: 'NRIC',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Uen,
        value: 'UEN',
        submitted: true,
        answerArray: false,
    },
    {
        name: types_1.BasicField.Table,
        value: 'Table',
        submitted: true,
        answerArray: true,
    },
];
/**
 * Array of BasicFields which are not included in the form response (e.g. statement)
 */
exports.FIELDS_TO_REJECT = exports.types
    .filter(function (f) { return !f.submitted; })
    .map(function (f) { return f.name; });
