"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var myInfoDialects = [
    'AKAN',
    'ALBANIAN',
    'AMHARIC',
    'ANNAMESE',
    'ARABIC',
    'ARMENIAN',
    'ASSAMESE',
    'AZERBAIJANI',
    'BANGLA',
    'BANJARESE',
    'BATAK',
    'BAUAN',
    'BENGALI',
    'BHUTANESE',
    'BOYANESE',
    'BUGIS',
    'BULGARIAN',
    'BURMESE',
    'CAMBODIAN',
    'CANTONESE',
    'CHALDEAN',
    'CHAWAN',
    'CHEKIANG',
    'CREOLE',
    'CROATIAN',
    'CZECH',
    'DANISH',
    'DAYAK',
    'DUSUN',
    'DUTCH',
    'ENGLISH',
    'FARSI',
    'FILIPINO',
    'FINNISH',
    'FLEMISH',
    'FOOCHOW',
    'FRENCH',
    'GERMAN',
    'GHANAIAN',
    'GOANESE',
    'GREEK',
    'GUJARATI',
    'GURKHALI',
    'HAINANESE',
    'HAKKA',
    'HEBREW',
    'HENGHUA',
    'HINDI',
    'HINDUSTANI',
    'HOCKCHIA',
    'HOKKIEN',
    'HUBEI',
    'HUNAN',
    'HUNGARIAN',
    'IBAN',
    'ICELANDIC',
    'ILOCANO',
    'ILONGO',
    'INDONESIAN',
    'IRISH',
    'ITALIAN',
    'JAFFNESE',
    'JAPANESE',
    'JAVANESE',
    'KADAZAN',
    'KANNADA',
    'KAREN',
    'KASHMIRI',
    'KAYAN',
    'KELABIT',
    'KHASI',
    'KHEK',
    'KHMER',
    'KIANGSI',
    'KIKUYU',
    'KONKANI',
    'KOREAN',
    'KWONGSAI',
    'LAO',
    'LITHUANIAN',
    'LUICHEW',
    'MALABARI',
    'MALAGASY',
    'MALAY',
    'MALAYALAM',
    'MALDIVIAN',
    'MALTESE',
    'MANCHU',
    'MANDARIN',
    'MANDINGO',
    'MAORI',
    'MARATHI',
    'MELANAU',
    'MINANGKABAU',
    'MONGOLIAN',
    'MONTENEGRIN',
    'MULTANI',
    'MYANMAR',
    'NEPALESE',
    'NEPALI',
    'NEWARI',
    'NORWEGIAN',
    'ORIYA',
    'OTHERS',
    'PAMPANGAN',
    'PATHAN',
    'PEKINESE',
    'PERSIAN',
    'POLISH',
    'PORTUGUESE',
    'PUNJABI',
    'PUSHTU',
    'RAKHINE',
    'ROMANIAN',
    'RUSSIAN',
    'SCOTTISH',
    'SERBIAN',
    'SHAN',
    'SHANGHAINESE',
    'SHANSI',
    'SHANTUNG',
    'SINDHI',
    'SINHALESE',
    'SLAVIC',
    'SLOVAK',
    'SPANISH',
    'SUNDANESE',
    'SWEDISH',
    'SWISS GERMAN',
    'SZECHUAN',
    'TAGALOG',
    'TAIWANESE',
    'TAMIL',
    'TELUGU',
    'TEOCHEW',
    'THAI',
    'TIBETAN',
    'TONGAN',
    'TURKISH',
    'UNKNOWN',
    'URDU',
    'VIETNAMESE',
    'VISAYAN',
    'WELSH',
    'WENCHOW',
    'YIDDISH',
];
exports.default = myInfoDialects;
