"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.startsWithSgPrefix = exports.isHomePhoneNumber = exports.isMobilePhoneNumber = exports.isPhoneNumber = void 0;
var max_1 = require("libphonenumber-js/max");
/**
 * Validates the phone number string using length information
 * @param phoneNumber
 * @returns true if validate, false otherwise
 */
var isPhoneNumber = function (phoneNumber) {
    var parsedNumber = max_1.parsePhoneNumberFromString(phoneNumber);
    if (!parsedNumber) {
        return false;
    }
    return parsedNumber.isPossible();
};
exports.isPhoneNumber = isPhoneNumber;
/**
 * Validates the mobile phone number string
 * @param mobileNumber
 * @returns true if validate, false otherwise
 */
var isMobilePhoneNumber = function (mobileNumber) {
    var parsedNumber = max_1.parsePhoneNumberFromString(mobileNumber);
    if (!parsedNumber)
        return false;
    if (exports.startsWithSgPrefix(mobileNumber)) {
        return (exports.isPhoneNumber(mobileNumber) &&
            // Regex checks if the national number starts with 8 or 9, and is of
            // length 8.
            !!parsedNumber.nationalNumber.match(/^[89][0-9]{7}$/g));
    }
    // Not Singapore number, check type and early return if undefined.
    var parsedType = parsedNumber.getType();
    if (!parsedType)
        return false;
    // All other countries uses number type to check for validity.
    return (exports.isPhoneNumber(mobileNumber) &&
        // Have to include both MOBILE, FIXED_LINE_OR_MOBILE as some countries lump
        // the types together.
        ['FIXED_LINE_OR_MOBILE', 'MOBILE'].includes(parsedType));
};
exports.isMobilePhoneNumber = isMobilePhoneNumber;
/**
 * Validates the given phone number string is a home phone number.
 * @param phoneNum the phone number string to validate
 * @returns true if validated, false otherwise.
 */
var isHomePhoneNumber = function (phoneNum) {
    var parsedNumber = max_1.parsePhoneNumberFromString(phoneNum);
    if (!parsedNumber)
        return false;
    // For SG numbers only check if it starts with 3 or 6 and has 8 digits
    // Leading number 3 is for IP Telephony (IPT) service and User-Centric Data-Only (UCDO) service
    // Leading number 6 is for  PSTN service and IP Telephony (IPT) service.
    // In both cases the length is 8
    // See IMDA's national numbering plan for specifications: https://www.imda.gov.sg/-/media/imda/files/regulation-licensing-and-consultations/frameworks-and-policies/numbering/national-numbering-plan-and-allocation-process/national-numbering-plan-30-nov-2017.pdf?la=en
    if (exports.startsWithSgPrefix(phoneNum)) {
        return (exports.isPhoneNumber(phoneNum) &&
            // Regex checks if the national number starts with 3 or 6, and is of
            // length 8.
            !!parsedNumber.nationalNumber.match(/^[36][0-9]{7}$/g));
    }
    // For intl numbers check number type as well
    var parsedType = parsedNumber.getType();
    if (!parsedType)
        return false;
    return (exports.isPhoneNumber(phoneNum) &&
        // Have to include both FIXED_LINE, FIXED_LINE_OR_MOBILE as some countries lump
        // the types together.
        ['FIXED_LINE', 'FIXED_LINE_OR_MOBILE'].includes(parsedType));
};
exports.isHomePhoneNumber = isHomePhoneNumber;
var startsWithSgPrefix = function (phoneNumber) {
    return phoneNumber.startsWith('+65');
};
exports.startsWithSgPrefix = startsWithSgPrefix;
