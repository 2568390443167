"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentSize = void 0;
var AttachmentSize;
(function (AttachmentSize) {
    AttachmentSize["OneMb"] = "1";
    AttachmentSize["TwoMb"] = "2";
    AttachmentSize["ThreeMb"] = "3";
    AttachmentSize["SevenMb"] = "7";
    AttachmentSize["TenMb"] = "10";
    AttachmentSize["TwentyMb"] = "20";
})(AttachmentSize = exports.AttachmentSize || (exports.AttachmentSize = {}));
