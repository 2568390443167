"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicType = exports.LogicIfValue = exports.LogicConditionState = void 0;
var LogicConditionState;
(function (LogicConditionState) {
    LogicConditionState["Equal"] = "is equals to";
    LogicConditionState["Lte"] = "is less than or equal to";
    LogicConditionState["Gte"] = "is more than or equal to";
    LogicConditionState["Either"] = "is either";
})(LogicConditionState = exports.LogicConditionState || (exports.LogicConditionState = {}));
var LogicIfValue;
(function (LogicIfValue) {
    LogicIfValue["Number"] = "number";
    LogicIfValue["SingleSelect"] = "single-select";
    LogicIfValue["MultiSelect"] = "multi-select";
})(LogicIfValue = exports.LogicIfValue || (exports.LogicIfValue = {}));
var LogicType;
(function (LogicType) {
    LogicType["ShowFields"] = "showFields";
    LogicType["PreventSubmit"] = "preventSubmit";
})(LogicType = exports.LogicType || (exports.LogicType = {}));
