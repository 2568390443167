"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVisibleFieldIds = exports.getLogicUnitPreventingSubmit = exports.groupLogicUnitsByField = exports.getApplicableIfStates = exports.getApplicableIfFields = exports.LOGIC_MAP = void 0;
var types_1 = require("../../types");
var LOGIC_CONDITIONS = [
    [
        types_1.BasicField.Dropdown,
        [types_1.LogicConditionState.Equal, types_1.LogicConditionState.Either],
    ],
    [
        types_1.BasicField.Number,
        [
            types_1.LogicConditionState.Equal,
            types_1.LogicConditionState.Lte,
            types_1.LogicConditionState.Gte,
        ],
    ],
    [
        types_1.BasicField.Decimal,
        [
            types_1.LogicConditionState.Equal,
            types_1.LogicConditionState.Lte,
            types_1.LogicConditionState.Gte,
        ],
    ],
    [
        types_1.BasicField.Rating,
        [
            types_1.LogicConditionState.Equal,
            types_1.LogicConditionState.Lte,
            types_1.LogicConditionState.Gte,
        ],
    ],
    [types_1.BasicField.YesNo, [types_1.LogicConditionState.Equal]],
    [types_1.BasicField.Radio, [types_1.LogicConditionState.Equal, types_1.LogicConditionState.Either]],
];
exports.LOGIC_MAP = new Map(LOGIC_CONDITIONS);
/**
 * Given a list of form fields, returns only the fields that are
 * allowed to be present in the if-condition dropdown in the Logic tab.
 */
var getApplicableIfFields = function (formFields) {
    return formFields.filter(function (field) { return !!exports.LOGIC_MAP.get(field.fieldType); });
};
exports.getApplicableIfFields = getApplicableIfFields;
/**
 * Given a single form field type, returns the applicable logic states for that field type.
 */
var getApplicableIfStates = function (fieldType) { var _a; return (_a = exports.LOGIC_MAP.get(fieldType)) !== null && _a !== void 0 ? _a : []; };
exports.getApplicableIfStates = getApplicableIfStates;
// Returns typed ShowFields logic unit
var isShowFieldsLogic = function (formLogic) {
    return formLogic.logicType === types_1.LogicType.ShowFields;
};
// Returns typed PreventSubmit logic unit
var isPreventSubmitLogic = function (formLogic) {
    return formLogic.logicType === types_1.LogicType.PreventSubmit;
};
/**
 * Parse logic into a map of fields that are shown/hidden depending on the
 * values of other fields.
 * Discards invalid logic, where the id in show or conditions do not exist in
 * the form_field.
 *
 * @example
 * Show Email (_id: 1001) and Number (_id: 1002) if Dropdown (_id: 1003) is "Option 1" and Yes_No (_id: 1004) is "Yes"
  Then,
  form_logics: [
    {
      show: ["1001","1002"],
      conditions: [
        {field: "1003", ifValueType: "single-select", state: "is equals to", value: "Option 1"},
        {field: "1004", ifValueType: "single-select", state: "is equals to", value: "Yes"}
       ]
    }
  ]

  logicUnitsGroupedByField:
  {
    "1001": [ [{field: "1003", ifValueType: "single-select", state: "is equals to", value: "Option 1"},
        {field: "1004", ifValueType: "single-select", state: "is equals to", value: "Yes"}] ],
    "1002": [ [{field: "1003", ifValueType: "single-select", state: "is equals to", value: "Option 1"},
        {field: "1004", ifValueType: "single-select", state: "is equals to", value: "Yes"}] ]
  }
 * @caption If "1001" is deleted, "1002" will still be rendered since we just won't add "1001" into logicUnitsGroupedByField
 *
 * @param form the form object to group its logic by field for
 * @returns an object containing fields to be displayed and their corresponding conditions, keyed by id of the displayable field
 */
var groupLogicUnitsByField = function (form) {
    var _a, _b, _c;
    var formId = form._id;
    var formLogics = (_b = (_a = form.form_logics) === null || _a === void 0 ? void 0 : _a.filter(isShowFieldsLogic)) !== null && _b !== void 0 ? _b : [];
    var formFieldIds = new Set((_c = form.form_fields) === null || _c === void 0 ? void 0 : _c.map(function (field) { return String(field._id); }));
    /** An index of logic units keyed by the field id to be shown. */
    var logicUnitsGroupedByField = {};
    var hasInvalidLogic = false;
    formLogics.forEach(function (logicUnit) {
        // Only add fields with valid logic conditions to the returned map.
        if (allConditionsExist(logicUnit.conditions, formFieldIds)) {
            logicUnit.show.forEach(function (fieldId) {
                fieldId = String(fieldId);
                if (formFieldIds.has(fieldId)) {
                    logicUnitsGroupedByField[fieldId] = logicUnitsGroupedByField[fieldId]
                        ? logicUnitsGroupedByField[fieldId]
                        : [];
                    logicUnitsGroupedByField[fieldId].push(logicUnit.conditions);
                }
            });
        }
        else {
            hasInvalidLogic = true;
        }
    });
    if (hasInvalidLogic && formId)
        console.info("formId=\"" + form._id + "\" message=\"Form has invalid logic\"");
    return logicUnitsGroupedByField;
};
exports.groupLogicUnitsByField = groupLogicUnitsByField;
/**
 * Parse logic to get a list of conditions where, if any condition in this list
 * is fulfilled, form submission is prevented.
 * @param form the form document to check
 * @returns array of conditions that prevent submission, can be empty
 */
var getPreventSubmitConditions = function (form) {
    var _a, _b, _c;
    var formFieldIds = new Set((_a = form.form_fields) === null || _a === void 0 ? void 0 : _a.map(function (field) { return String(field._id); }));
    var preventFormLogics = (_c = (_b = form.form_logics) === null || _b === void 0 ? void 0 : _b.filter(function (formLogic) {
        return isPreventSubmitLogic(formLogic) &&
            allConditionsExist(formLogic.conditions, formFieldIds);
    })) !== null && _c !== void 0 ? _c : [];
    return preventFormLogics;
};
/**
 * Determines whether the submission should be prevented by form logic. If so,
 * return the condition preventing the submission. If not, return undefined.
 * @param submission the submission responses to retrieve logic units for. Can be `form_fields` (on client), or `req.body.responses` (on server)
 * @param form the form document for the submission
 * @param optionalVisibleFieldIds the optional set of currently visible fields. If this is not provided, it will be recomputed using the given form parameter.
 * @returns a condition if submission is to prevented, otherwise `undefined`
 */
var getLogicUnitPreventingSubmit = function (submission, form, visibleFieldIds) {
    var definedVisibleFieldIds = visibleFieldIds !== null && visibleFieldIds !== void 0 ? visibleFieldIds : exports.getVisibleFieldIds(submission, form);
    var preventSubmitConditions = getPreventSubmitConditions(form);
    return preventSubmitConditions.find(function (logicUnit) {
        return isLogicUnitSatisfied(submission, logicUnit.conditions, definedVisibleFieldIds);
    });
};
exports.getLogicUnitPreventingSubmit = getLogicUnitPreventingSubmit;
/**
 * Checks if the field ids in logic's conditions all exist in the fieldIds.
 * @param conditions the list of conditions to check
 * @param formFieldIds the set of form field ids to check
 * @returns true if every condition's related form field id exists in the set of formFieldIds, false otherwise.
 */
var allConditionsExist = function (conditions, formFieldIds) {
    return conditions.every(function (condition) {
        return formFieldIds.has(String(condition.field));
    });
};
/**
 * Gets the IDs of visible fields in a form according to its responses.
 * This function loops through all the form fields until the set of visible
 * fields no longer changes. The first loop adds all the fields with no
 * conditions attached, the second adds fields which are made visible due to fields added in the previous loop, and so on.
 * @param submission the submission responses to retrieve logic units for. Can be `form_fields` (on client), or `req.body.responses` (on server)
 * @param form the form document for the submission
 * @returns a set of IDs of visible fields in the submission
 */
var getVisibleFieldIds = function (submission, form) {
    var _a;
    var logicUnitsGroupedByField = exports.groupLogicUnitsByField(form);
    var visibleFieldIds = new Set();
    // Loop continues until no more changes made
    var changesMade = true;
    while (changesMade) {
        changesMade = false;
        (_a = form.form_fields) === null || _a === void 0 ? void 0 : _a.forEach(function (field) {
            var logicUnits = logicUnitsGroupedByField[String(field._id)];
            // If a field's visibility does not have any conditions, it is always
            // visible.
            // Otherwise, a field's visibility can be toggled by a combination of
            // conditions.
            // Eg. the following are logicUnits - just one of them has to be satisfied
            // 1) Show X if Y=yes and Z=yes
            // Or
            // 2) Show X if A=1
            if (!visibleFieldIds.has(field._id.toString()) &&
                (!logicUnits ||
                    logicUnits.some(function (logicUnit) {
                        return isLogicUnitSatisfied(submission, logicUnit, visibleFieldIds);
                    }))) {
                visibleFieldIds.add(field._id.toString());
                changesMade = true;
            }
        });
    }
    return visibleFieldIds;
};
exports.getVisibleFieldIds = getVisibleFieldIds;
/**
 * Checks if an array of conditions is satisfied.
 * @param submission the submission responses to retrieve logic units for. Can be `form_fields` (on client), or `req.body.responses` (on server)
 * @param logicUnit an object containing the conditions specified in a single modal of `add new logic` on the form logic tab
 * @param visibleFieldIds the set of field IDs that are visible, which is used to ensure that conditions are visible
 * @returns true if all the conditions are satisfied, false otherwise
 */
var isLogicUnitSatisfied = function (submission, logicUnit, visibleFieldIds) {
    return logicUnit.every(function (condition) {
        var conditionField = findConditionField(submission, condition.field);
        return (conditionField &&
            visibleFieldIds.has(conditionField._id.toString()) &&
            isConditionFulfilled(conditionField, condition));
    });
};
var getCurrentValue = function (field) {
    if ('fieldValue' in field) {
        // client
        return field.fieldValue;
    }
    else if ('answer' in field) {
        // server
        return field.answer;
    }
    return null;
};
/**
 * Checks if the field's value matches the condition
 * @param {Object} field
 * @param {Object} condition
 * @param {String} condition.state - The type of condition
 */
var isConditionFulfilled = function (field, condition) {
    if (!field || !condition) {
        return false;
    }
    var currentValue = getCurrentValue(field);
    if (currentValue === null ||
        currentValue === undefined ||
        currentValue.length === 0) {
        return false;
    }
    if (condition.state === types_1.LogicConditionState.Equal ||
        condition.state === types_1.LogicConditionState.Either) {
        // condition.value can be a string (is equals to), or an array (is either)
        var conditionValues = []
            .concat(condition.value)
            .map(String);
        currentValue = String(currentValue);
        /*
        Handling 'Others' for radiobutton
    
        form_logics: [{ ... value : 'Others' }]
    
        Client-side:
        When an Others radiobutton is checked, the fieldValue is 'radioButtonOthers'
    
        Server-side:
        When an Others radiobutton is checked, and submitted with the required value,
        the answer is: 'Others: value'
        */
        // TODO: An option that is named "Others: Something..." will also pass this test,
        // even if the field has not been configured to set othersRadioButton=true
        if (conditionValues.indexOf('Others') > -1) {
            if (field.fieldType === 'radiobutton') {
                conditionValues.push('radioButtonOthers');
            }
            else if (field.fieldType === 'checkbox') {
                conditionValues.push('checkboxOthers'); // Checkbox currently doesn't have logic, but the 'Others' will work in the future if it in implemented
            }
            return (conditionValues.indexOf(currentValue) > -1 || // Client-side
                currentValue.startsWith('Others: ')); // Server-side
        }
        return conditionValues.indexOf(currentValue) > -1;
    }
    else if (condition.state === 'is less than or equal to') {
        return Number(currentValue) <= Number(condition.value);
    }
    else if (condition.state === 'is more than or equal to') {
        return Number(currentValue) >= Number(condition.value);
    }
    else {
        return false;
    }
};
/**
 * Find the field in the current submission corresponding to the condition to be
 * checked.
 * @param submission the submission responses to retrieve logic units for. Can be `form_fields` (on client), or `req.body.responses` (on server)
 * @param fieldId the id of condition field to find
 * @returns the condition field if it exists, `undefined` otherwise
 */
var findConditionField = function (submission, fieldId) {
    return submission.find(function (submittedField) { return String(submittedField._id) === String(fieldId); });
};
