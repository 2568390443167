"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getResponseInstance = void 0;
var has_prop_1 = require("../../../../shared/util/has-prop");
var field_1 = require("../../../../types/field");
var csv_response_classes_1 = require("./csv-response-classes");
/**
 * Converts a field record into a custom response instance
 * @param fieldRecordData Field record
 * @returns Response instance
 * @throws Error when data does not fit any known response type
 */
var getResponseInstance = function (fieldRecordData) {
    if (isNestedResponse(fieldRecordData) &&
        fieldRecordData.fieldType === field_1.BasicField.Table) {
        return new csv_response_classes_1.TableResponse(fieldRecordData);
    }
    else if (isArrayResponse(fieldRecordData) &&
        fieldRecordData.fieldType === field_1.BasicField.Checkbox) {
        return new csv_response_classes_1.ArrayAnswerResponse(fieldRecordData);
    }
    else if (isSingleResponse(fieldRecordData)) {
        return new csv_response_classes_1.SingleAnswerResponse(fieldRecordData);
    }
    else {
        // eslint-disable-next-line typesafe/no-throw-sync-func
        throw new Error('Response did not match any known type');
    }
};
exports.getResponseInstance = getResponseInstance;
var isNestedResponse = function (response) {
    return (hasAnswerArray(response) &&
        response.answerArray.every(function (arr) {
            return Array.isArray(arr) &&
                arr.every(function (value) { return typeof value === 'string'; });
        }));
};
var isArrayResponse = function (response) {
    return (hasAnswerArray(response) &&
        response.answerArray.every(function (value) { return typeof value === 'string'; }));
};
var isSingleResponse = function (response) {
    return has_prop_1.hasProp(response, 'answer') && typeof response.answer === 'string';
};
var hasAnswerArray = function (response) {
    return has_prop_1.hasProp(response, 'answerArray') && Array.isArray(response.answerArray);
};
