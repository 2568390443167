"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDto = exports.UserBase = void 0;
var zod_1 = require("zod");
var generic_1 = require("./generic");
var agency_1 = require("./agency");
// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
exports.UserBase = zod_1.z.object({
    _id: zod_1.z.unknown(),
    email: zod_1.z.string().email(),
    agency: agency_1.AgencyBase.shape._id,
    betaFlags: zod_1.z.record(zod_1.z.boolean()).optional(),
    created: zod_1.z.date(),
    lastAccessed: zod_1.z.date().optional(),
    updatedAt: zod_1.z.date(),
    contact: zod_1.z.string().optional(),
});
// Convert to serialized versions.
exports.UserDto = exports.UserBase.extend({
    _id: zod_1.z.string(),
    agency: agency_1.AgencyDto.extend({
        created: generic_1.DateString,
        lastModified: generic_1.DateString,
    }),
    created: generic_1.DateString,
    lastAccessed: generic_1.DateString.optional(),
    updatedAt: generic_1.DateString,
});
