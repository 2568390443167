'use strict'
const { Colors } = require('../../../../../types/form.ts')
const axios = require('axios').default
const {
  MAX_UPLOAD_FILE_SIZE,
  VALID_UPLOAD_FILE_TYPES,
  MB,
} = require('shared/constants')
const { uploadLogo } = require('../../../../services/FileHandlerService')
const { FormLogoState } = require('../../../../../types')
const { getFormLogo } = require('../../helpers/logo')

const CancelToken = axios.CancelToken

angular
  .module('forms')
  .controller('EditStartPageController', [
    '$uibModalInstance',
    '$q',
    'myform',
    'updateStartPage',
    EditStartPageController,
  ])

function EditStartPageController(
  $uibModalInstance,
  $q,
  myform,
  updateStartPage,
) {
  let source
  const vm = this

  vm.maxLogoSize = MAX_UPLOAD_FILE_SIZE
  vm.validLogoExtensions = VALID_UPLOAD_FILE_TYPES
  vm.MB = MB
  vm.FormLogoState = FormLogoState

  vm.logoUrl = getFormLogo(myform)

  // Make a copy so nothing is changed in the original.
  vm.myform = angular.copy(myform)
  vm.colorThemes = Object.values(Colors)
  vm.hasClickedSave = false

  vm.saveStartPage = function (isValid) {
    vm.hasClickedSave = true
    const logoState = vm.myform.startPage.logo.state
    // Clones the start page so that the original one can be used.
    // This prevents the actual start page from being affected in the event of failure
    const clonedStartPage = angular.copy(vm.myform.startPage)

    if (isValid) {
      if (logoState !== FormLogoState.Custom) {
        clonedStartPage.logo = {
          state: logoState,
        }
      }
      $q.when(updateStartPage({ newStartPage: clonedStartPage })).then(
        (error) => {
          if (!error) {
            vm.hasClickedSave = false
            $uibModalInstance.close()
          }
        },
      )
    }
  }

  /**
   * Model storing currently uploaded file for file upload button
   */
  vm.uploaded = { file: '' }

  /**
   * Form logo states to show for radio buttons
   */
  vm.formLogoStates = [
    {
      name: 'Default',
      enum: FormLogoState.Default,
    },
    {
      name: 'No logo',
      enum: FormLogoState.None,
    },
    {
      name: 'Upload (jpg, png, or gif)',
      enum: FormLogoState.Custom,
    },
  ]

  /**
   * Show loading icon before resizing logo
   */
  vm.beforeResizing = () => {
    vm.uploading = true
    vm.cancelUpload()
  }

  vm.cancelUpload = () => {
    if (source) {
      source.cancel()
    }
  }

  /**
   * Upload logo to persistent storage
   * @param {File} logo - Uploaded by user
   * @param {Object} error - Generated by ng file upload
   */
  vm.uploadLogo = function (logo, ngfError) {
    if (ngfError) {
      vm.uploading = false
      // This is a reference to the ng-model of the upload button, which points to the uploaded file
      // On error, we explicitly clear the files stored in the model, as the library does not always automatically do this

      vm.uploaded.file = ''
      switch (ngfError.$error) {
        case 'maxSize':
          vm.uploadError = `${(ngfError.size / MB).toFixed(2)} MB / ${
            vm.maxLogoSize / MB
          } MB: File size exceeded`
          break
        case 'resize':
          vm.uploadError = `An error has occurred while resizing your image`
          break
        default:
          vm.uploadError = 'Oops something went wrong. Please try again!'
      }
    } else if (logo) {
      vm.uploadError = null
      source = CancelToken.source()

      $q.when(
        uploadLogo({
          image: logo,
          formId: vm.myform._id,
          cancelToken: source.token,
        }),
      )
        .then((result) => {
          vm.myform.startPage.logo = {
            state: FormLogoState.Custom,
            fileId: result.fileId,
            fileName: result.name,
            fileSizeInBytes: result.size,
          }
          // Updates logoUrl for frontend
          vm.logoUrl = getFormLogo(vm.myform)
        })
        .catch((uploadError) => {
          // This is a reference to the ng-model of the upload button, which points to the uploaded file
          // On error, we explicitly clear the files stored in the model, as the library does not always automatically do this
          vm.uploaded.file = ''

          if (axios.isCancel(uploadError)) {
            vm.uploadError = `Upload cancelled. Please try again!`
          } else {
            console.error(uploadError)
            vm.uploadError = 'Upload error. Please try again!'
          }
          vm.removeLogo()
        })
        .finally(() => {
          vm.uploading = false
        })
    }
  }

  /**
   * Clear logo and possibly delete off persistent storage
   */
  vm.removeLogo = function () {
    vm.myform.startPage.logo = {
      state: FormLogoState.Custom,
      fileId: '',
      fileName: '',
      fileSizeInBytes: 0,
    }

    // This is a reference to the ng-model of the upload button, which points to the uploaded file
    // On error, we explicitly clear the files stored in the model, as the library does not always automatically do this
    vm.uploaded.file = ''
  }

  vm.cancel = function () {
    $uibModalInstance.close()
  }

  vm.updateLogoUrl = function () {
    vm.logoUrl = getFormLogo(vm.myform)
  }
}
