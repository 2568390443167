"use strict";
/**
 * Utility to narrow type of an object by determining whether
 * it contains the given property.
 * @param obj Object
 * @param prop Property to check
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasProp = void 0;
var hasProp = function (obj, prop) {
    return typeof obj === 'object' && obj !== null && prop in obj;
};
exports.hasProp = hasProp;
