"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VfnErrors = exports.NUM_OTP_RETRIES = exports.WAIT_FOR_OTP_TOLERANCE_SECONDS = exports.WAIT_FOR_OTP_SECONDS = exports.HASH_EXPIRE_AFTER_SECONDS = exports.TRANSACTION_EXPIRE_AFTER_SECONDS = exports.SALT_ROUNDS = exports.VERIFIED_FIELDTYPES = void 0;
var types_1 = require("../../types");
exports.VERIFIED_FIELDTYPES = [types_1.BasicField.Email, types_1.BasicField.Mobile];
exports.SALT_ROUNDS = 10;
exports.TRANSACTION_EXPIRE_AFTER_SECONDS = 14400; // 4 hours
exports.HASH_EXPIRE_AFTER_SECONDS = 60 * 30; // 30 minutes
exports.WAIT_FOR_OTP_SECONDS = 30;
/**
 * WAIT_FOR_OTP_SECONDS tolerance. Server allows OTPs to be requested every
 * (WAIT_FOR_OTP_SECONDS - WAIT_FOR_OTP_TOLERANCE_SECONDS) seconds.
 */
exports.WAIT_FOR_OTP_TOLERANCE_SECONDS = 2;
exports.NUM_OTP_RETRIES = 4;
var VfnErrors;
(function (VfnErrors) {
    VfnErrors["ResendOtp"] = "RESEND_OTP";
    VfnErrors["SendOtpFailed"] = "SEND_OTP_FAILED";
    VfnErrors["WaitForOtp"] = "WAIT_FOR_OTP";
    VfnErrors["InvalidOtp"] = "INVALID_OTP";
    VfnErrors["FieldNotFound"] = "FIELD_NOT_FOUND";
    VfnErrors["TransactionNotFound"] = "TRANSACTION_NOT_FOUND";
    VfnErrors["InvalidMobileNumber"] = "INVALID_MOBILE_NUMBER";
})(VfnErrors = exports.VfnErrors || (exports.VfnErrors = {}));
