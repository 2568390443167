"use strict";
var _a, _b, _c, _d, _e, _f;
Object.defineProperty(exports, "__esModule", { value: true });
exports.injectedVariables = void 0;
// NOTE: As these variables are not injected until runtime
// window is declared as any so that we can access the property without ts complaining
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var formsgWindow = window;
exports.injectedVariables = {
    isGeneralMaintenance: formsgWindow.isGeneralMaintenance,
    isLoginBanner: formsgWindow.isLoginBanner,
    siteBannerContent: formsgWindow.siteBannerContent,
    adminBannerContent: formsgWindow.adminBannerContent,
    logoBucketUrl: formsgWindow.logoBucketUrl,
    formsgSdkMode: formsgWindow.formsgSdkMode,
    captchaPublicKey: (_a = formsgWindow.captchaPublicKey) !== null && _a !== void 0 ? _a : null,
    sentryConfigUrl: (_b = formsgWindow.sentryConfigUrl) !== null && _b !== void 0 ? _b : null,
    isSPMaintenance: (_c = formsgWindow.isSPMaintenance) !== null && _c !== void 0 ? _c : null,
    isCPMaintenance: (_d = formsgWindow.isCPMaintenance) !== null && _d !== void 0 ? _d : null,
    GATrackingID: (_e = formsgWindow.GATrackingID) !== null && _e !== void 0 ? _e : null,
    spcpCookieDomain: (_f = formsgWindow.spcpCookieDomain) !== null && _f !== void 0 ? _f : null,
};
