"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SgidFieldTitle = exports.SPCPFieldTitle = exports.MyInfoAttribute = exports.BasicField = void 0;
var BasicField;
(function (BasicField) {
    BasicField["Section"] = "section";
    BasicField["Statement"] = "statement";
    BasicField["Email"] = "email";
    BasicField["Mobile"] = "mobile";
    BasicField["HomeNo"] = "homeno";
    BasicField["Number"] = "number";
    BasicField["Decimal"] = "decimal";
    BasicField["Image"] = "image";
    BasicField["ShortText"] = "textfield";
    BasicField["LongText"] = "textarea";
    BasicField["Dropdown"] = "dropdown";
    BasicField["YesNo"] = "yes_no";
    BasicField["Checkbox"] = "checkbox";
    BasicField["Radio"] = "radiobutton";
    BasicField["Attachment"] = "attachment";
    BasicField["Date"] = "date";
    BasicField["Rating"] = "rating";
    BasicField["Nric"] = "nric";
    BasicField["Table"] = "table";
    BasicField["Uen"] = "uen";
})(BasicField = exports.BasicField || (exports.BasicField = {}));
var MyInfoAttribute;
(function (MyInfoAttribute) {
    MyInfoAttribute["Name"] = "name";
    MyInfoAttribute["PassportNumber"] = "passportnumber";
    MyInfoAttribute["RegisteredAddress"] = "regadd";
    MyInfoAttribute["Employment"] = "employment";
    MyInfoAttribute["VehicleNo"] = "vehno";
    MyInfoAttribute["MarriageCertNo"] = "marriagecertno";
    MyInfoAttribute["Sex"] = "sex";
    MyInfoAttribute["Race"] = "race";
    MyInfoAttribute["Dialect"] = "dialect";
    MyInfoAttribute["Nationality"] = "nationality";
    MyInfoAttribute["BirthCountry"] = "birthcountry";
    MyInfoAttribute["ResidentialStatus"] = "residentialstatus";
    MyInfoAttribute["HousingType"] = "housingtype";
    MyInfoAttribute["HdbType"] = "hdbtype";
    MyInfoAttribute["Marital"] = "marital";
    MyInfoAttribute["CountryOfMarriage"] = "countryofmarriage";
    MyInfoAttribute["WorkpassStatus"] = "workpassstatus";
    MyInfoAttribute["Occupation"] = "occupation";
    MyInfoAttribute["MobileNo"] = "mobileno";
    MyInfoAttribute["DateOfBirth"] = "dob";
    MyInfoAttribute["PassportExpiryDate"] = "passportexpirydate";
    MyInfoAttribute["MarriageDate"] = "marriagedate";
    MyInfoAttribute["DivorceDate"] = "divorcedate";
    MyInfoAttribute["WorkpassExpiryDate"] = "workpassexpirydate";
})(MyInfoAttribute = exports.MyInfoAttribute || (exports.MyInfoAttribute = {}));
var SPCPFieldTitle;
(function (SPCPFieldTitle) {
    SPCPFieldTitle["SpNric"] = "SingPass Validated NRIC";
    SPCPFieldTitle["CpUid"] = "CorpPass Validated UID";
    SPCPFieldTitle["CpUen"] = "CorpPass Validated UEN";
})(SPCPFieldTitle = exports.SPCPFieldTitle || (exports.SPCPFieldTitle = {}));
var SgidFieldTitle;
(function (SgidFieldTitle) {
    SgidFieldTitle["SgidNric"] = "sgID Validated NRIC";
})(SgidFieldTitle = exports.SgidFieldTitle || (exports.SgidFieldTitle = {}));
