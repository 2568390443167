"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Response = exports.TableResponse = exports.ArrayAnswerResponse = exports.SingleAnswerResponse = void 0;
var SingleAnswerResponse_class_1 = require("./SingleAnswerResponse.class");
Object.defineProperty(exports, "SingleAnswerResponse", { enumerable: true, get: function () { return SingleAnswerResponse_class_1.SingleAnswerResponse; } });
var ArrayAnswerResponse_class_1 = require("./ArrayAnswerResponse.class");
Object.defineProperty(exports, "ArrayAnswerResponse", { enumerable: true, get: function () { return ArrayAnswerResponse_class_1.ArrayAnswerResponse; } });
var TableResponse_class_1 = require("./TableResponse.class");
Object.defineProperty(exports, "TableResponse", { enumerable: true, get: function () { return TableResponse_class_1.TableResponse; } });
var Response_class_1 = require("./Response.class");
Object.defineProperty(exports, "Response", { enumerable: true, get: function () { return Response_class_1.Response; } });
