"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Response = void 0;
var Response = /** @class */ (function () {
    function Response(responseData) {
        this.data = responseData;
    }
    Object.defineProperty(Response.prototype, "id", {
        get: function () {
            return this.data._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Response.prototype, "question", {
        /**
         * Gets the CSV header.
         * @returns {string}
         */
        get: function () {
            return this.data.question;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Response.prototype, "isHeader", {
        get: function () {
            var _a;
            return (_a = this.data.isHeader) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    return Response;
}());
exports.Response = Response;
