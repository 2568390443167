"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environment = void 0;
// Enums
var Environment;
(function (Environment) {
    Environment["Dev"] = "development";
    Environment["Prod"] = "production";
    Environment["Test"] = "test";
})(Environment = exports.Environment || (exports.Environment = {}));
