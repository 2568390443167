"use strict";
/**
 * This utility file contains immutable array functions.
 */
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceAt = exports.reorder = void 0;
/**
 * Pure function to reorders given array from given `fromIndex` to `toIndex`.
 * The new reordered array will be returned.
 *
 * If initial `fromIndex` is out of bounds of the original array, no reordering
 * will be performed and the initial array will be returned.
 *
 * Function retrieved from
 * https://github.com/granteagon/move/blob/master/src/index.js and converted to
 * TypeScript and renamed for clarity.
 *
 * @param array initial array to reorder
 * @param fromIndex the current index of the element to move
 * @param toIndex the new index to move the element to
 * @returns reordered array
 */
var reorder = function (array, fromIndex, toIndex) {
    /**
     * Invalid index, return array as is.
     * The index is checked instead of definedness of element at the index as
     * given array may contain undefined elements and will not be a comprehensive
     * validity check.
     */
    if (fromIndex < 0 || fromIndex >= array.length) {
        return array;
    }
    var elementToMove = array[fromIndex];
    var diff = fromIndex - toIndex;
    if (diff > 0) {
        // Reorder to the left.
        return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], array.slice(0, toIndex)), [
            elementToMove
        ]), array.slice(toIndex, fromIndex)), array.slice(fromIndex + 1, array.length));
    }
    else if (diff < 0) {
        // Reorder to the right.
        var targetIndex = toIndex + 1;
        return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], array.slice(0, fromIndex)), array.slice(fromIndex + 1, targetIndex)), [
            elementToMove
        ]), array.slice(targetIndex, array.length));
    }
    return array;
};
exports.reorder = reorder;
/**
 * Pure function to replace element at given `index` with `newValue`.
 *
 * @param array initial array to replace element for
 * @param index index to replace element at
 * @param newValue the new value to replace with
 *
 * @return new array with replaced value
 */
var replaceAt = function (array, index, newValue) {
    var ret = array.slice(0);
    ret[index] = newValue;
    return ret;
};
exports.replaceAt = replaceAt;
