"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userHasAccessToFeature = exports.userHasAccessToFieldType = exports.isBetaField = exports.getMissingBetaPermissions = void 0;
var lodash_1 = require("lodash");
// Re-use the backend types for now so that we have some type safety.
// Given that this is used mostly by JavaScript modules, the lack of
// mongo-specific types should not present a problem.
// Change the types to frontend equivalents as and when available.
var types_1 = require("../../types");
var BETA_FEATURES = [
    // This is an example of how to add fields to this object
    // {
    //   name: 'betaFlagName',
    //   flag: 'hasBetaFlag',
    //   matches: (form) => {
    //     return form.form_fields.some((field) =>
    //      field.fieldType === 'mobile' && field.isVerifiable
    //     )
    //   },
    //  fieldType: 'mobile',
    // },
    // sgID: an authentication mechanism similar to Singpass
    {
        name: 'SGID',
        flag: 'sgid',
        matches: function (form) { return form.authType === types_1.AuthType.SGID; },
        // SGID is associated with entire form, not individual field
        fieldType: null,
    },
];
var getBetaFeaturesForForm = function (form, betaFeaturesField) {
    var betaFeatures = new Set();
    betaFeaturesField.forEach(function (feature) {
        if (feature.matches(form)) {
            betaFeatures.add(feature.name);
        }
    });
    return Array.from(betaFeatures);
};
var getMissingBetaPermissions = function (user, form, betaFeaturesField) {
    if (betaFeaturesField === void 0) { betaFeaturesField = BETA_FEATURES; }
    var betaFeatures = getBetaFeaturesForForm(form, betaFeaturesField);
    return betaFeatures.filter(function (name) {
        var _a;
        var flag = (_a = betaFeaturesField.find(function (feature) { return feature.name === name; })) === null || _a === void 0 ? void 0 : _a.flag;
        return flag && !lodash_1.get(user, ['betaFlags', flag], false);
    });
};
exports.getMissingBetaPermissions = getMissingBetaPermissions;
var isBetaField = function (fieldType, betaFeaturesField) {
    if (betaFeaturesField === void 0) { betaFeaturesField = BETA_FEATURES; }
    return betaFeaturesField.find(function (feature) { return feature.fieldType === fieldType; });
};
exports.isBetaField = isBetaField;
var userHasAccessToFieldType = function (user, fieldType, betaFeaturesField) {
    var _a;
    if (betaFeaturesField === void 0) { betaFeaturesField = BETA_FEATURES; }
    var flag = (_a = betaFeaturesField.find(function (feature) {
        return feature.fieldType === fieldType;
    })) === null || _a === void 0 ? void 0 : _a.flag;
    // No beta limitations on this field type
    if (!flag)
        return true;
    return (!exports.isBetaField(fieldType, betaFeaturesField) ||
        (Boolean(flag) && lodash_1.get(user, ['betaFlags', flag], false)));
};
exports.userHasAccessToFieldType = userHasAccessToFieldType;
var userHasAccessToFeature = function (user, flag) {
    return lodash_1.get(user, ['betaFlags', flag], false);
};
exports.userHasAccessToFeature = userHasAccessToFeature;
