"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicAgencyDto = exports.AgencyDto = exports.AgencyBase = exports.AgencyId = void 0;
var zod_1 = require("zod");
var generic_1 = require("./generic");
exports.AgencyId = zod_1.z.string();
// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
exports.AgencyBase = zod_1.z.object({
    _id: zod_1.z.unknown(),
    emailDomain: zod_1.z.array(zod_1.z.string()),
    fullName: zod_1.z.string(),
    shortName: zod_1.z.string(),
    logo: zod_1.z.string(),
});
exports.AgencyDto = exports.AgencyBase.extend({
    _id: exports.AgencyId,
    created: generic_1.DateString,
    lastModified: generic_1.DateString,
});
exports.PublicAgencyDto = exports.AgencyDto.pick({
    shortName: true,
    fullName: true,
    emailDomain: true,
    logo: true,
});
