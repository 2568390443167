"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CsvGenerator = void 0;
var csv_string_1 = require("csv-string");
var util_1 = require("./util");
// Used to denote to Excel that the CSV is UTF8-encoded. See
// https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
// for more information.
var UTF8_BYTE_ORDER_MARK = '\uFEFF';
var CsvGenerator = /** @class */ (function () {
    function CsvGenerator(expectedNumberOfRecords, numOfMetaDataRows) {
        if (expectedNumberOfRecords === void 0) { expectedNumberOfRecords = 0; }
        if (numOfMetaDataRows === void 0) { numOfMetaDataRows = 0; }
        this.expectedNumberOfRecords = expectedNumberOfRecords;
        this.numOfMetaDataRows = numOfMetaDataRows;
        this.numOfHeaderRows = 1;
        this.lastCreatedAt = 0;
        // Index to start inserting new data into.
        // (Assume 1 row for header and 1 for the byte order mark.)
        this.startIdx = this.numOfMetaDataRows + this.numOfHeaderRows + 1;
        // Index to insert next row data into.
        this.idx = this.startIdx;
        // Preallocate for performance.
        this.records = Array(this.startIdx + this.expectedNumberOfRecords).fill('');
        // Must be added first for Excel to know that this file is an UTF8-encoded
        // CSV.
        this.records[0] = UTF8_BYTE_ORDER_MARK;
    }
    /**
     * Insert raw data for a given row into the CSV file
     */
    CsvGenerator.prototype.addLine = function (rowData) {
        this.records[this.idx] = csv_string_1.stringify(rowData);
        this.idx++;
    };
    /**
     * Insert headers into the CSV file after the meta-data
     */
    CsvGenerator.prototype.setHeader = function (headerLabels) {
        this.records[this.startIdx - 1] = csv_string_1.stringify(headerLabels);
    };
    /**
     * Insert meta-data array into the start of the CSV file
     */
    CsvGenerator.prototype.addMetaData = function (metaDataRows) {
        var _a;
        var metaData = metaDataRows.map(function (data) { return csv_string_1.stringify(data); });
        // Start splicing at index 1 because BOM is at index 0.
        (_a = this.records).splice.apply(_a, __spreadArray([1, this.numOfMetaDataRows], metaData));
    };
    /**
     * Download CSV file to disk
     */
    CsvGenerator.prototype.triggerFileDownload = function (fileName) {
        var blob = new Blob(this.records, {
            type: 'text/csv;charset=utf-8',
        });
        util_1.triggerFileDownload(blob, fileName);
    };
    /**
     * Returns current length of CSV file excluding header and meta-data
     */
    CsvGenerator.prototype.length = function () {
        return this.idx - this.startIdx;
    };
    return CsvGenerator;
}());
exports.CsvGenerator = CsvGenerator;
