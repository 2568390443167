"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_FORM_TYPES = void 0;
exports.UPDATE_FORM_TYPES = {
    UpdateField: 'update-field',
    CreateField: 'create-field',
    DeleteField: 'delete-field',
    DuplicateField: 'duplicate-field',
    ReorderField: 'reorder-field',
};
