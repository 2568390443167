const iconTypeMap = {
  // Regular fields
  textfield: 'bx bx-rename',
  dropdown: 'bx bx-select-arrows',
  date: 'bx bx-calendar',
  checkbox: 'bx bx-checkbox-checked',
  email: 'bx bx-envelope',
  mobile: 'bx bx-mobile-alt',
  textarea: 'bx bx-left-indent',
  rating: 'bx bx-star',
  statement: 'bx bx-quote-left',
  section: 'bx bx-heading',
  yes_no: 'bx bx-toggle',
  number: 'bx bx-hash',
  decimal: 'bx bx-calculator',
  image: 'bx bx-image',
  nric: 'bx bx-user',
  uen: 'bx bx-building',
  attachment: 'bx bx-cloud-download',
  radiobutton: 'bx bx-radio-circle-marked',
  table: 'bx bx-table',
  // MyInfo attributes
  name: 'bx bx-user-circle',
  sex: 'bx bx-male',
  race: 'bx bx-body',
  nationality: 'bx bx-globe',
  birthcountry: 'bx bx-flag-alt',
  dialect: 'bx bx-quote-left',
  residentialstatus: 'bx bx-certification',
  housingtype: 'bx bx-home-alt',
  hdbtype: 'bx bx-home',
  marital: 'bx bx-diamond',
  countryofmarriage: 'bx bx-globe-alt',
  workpassstatus: 'bx bx-task',
  // householdincome: "",
  // marriedname: "",
  // hanyupinyinname: "",
  // aliasname: "",
  // hanyupinyinaliasname: "",
  // secondaryrace: "",
  passportnumber: 'bx bx-world',
  regadd: 'bx bx-building',
  occupation: 'bx bx-briefcase',
  employment: 'bx bx-contact',
  vehno: 'bx bx-car',
  dob: 'bx bx-calendar-plus',
  passportexpirydate: 'bx bx-calendar-minus',
  marriagedate: 'bx bx-calendar-check',
  divorcedate: 'bx bx-calendar-x',
  workpassexpirydate: 'bx bx-calendar-alt',
  homeno: 'bx bx-phone',
  mobileno: 'bx bx-mobile-alt',
  marriagecertno: 'bx bx-award',
}

module.exports = {
  iconTypeMap,
}
