"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LINKS = exports.MAX_UPLOAD_FILE_SIZE = exports.MB = exports.KB = exports.VALID_UPLOAD_FILE_TYPES = exports.DateSelectedValidation = exports.FilePlatforms = exports.EditFieldActions = void 0;
// Enum of actions that can be used to edit a form field
var EditFieldActions;
(function (EditFieldActions) {
    EditFieldActions["Create"] = "CREATE";
    EditFieldActions["Duplicate"] = "DUPLICATE";
    EditFieldActions["Reorder"] = "REORDER";
    EditFieldActions["Update"] = "UPDATE";
    EditFieldActions["Delete"] = "DELETE";
})(EditFieldActions = exports.EditFieldActions || (exports.EditFieldActions = {}));
var FilePlatforms;
(function (FilePlatforms) {
    FilePlatforms["Browser"] = "browser";
    FilePlatforms["Server"] = "server";
})(FilePlatforms = exports.FilePlatforms || (exports.FilePlatforms = {}));
// Enum of date validation options
var DateSelectedValidation;
(function (DateSelectedValidation) {
    DateSelectedValidation["NoPast"] = "Disallow past dates";
    DateSelectedValidation["NoFuture"] = "Disallow future dates";
    DateSelectedValidation["Custom"] = "Custom date range";
})(DateSelectedValidation = exports.DateSelectedValidation || (exports.DateSelectedValidation = {}));
// File types that can be uploaded for form image/logo
exports.VALID_UPLOAD_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
exports.KB = 1000;
exports.MB = 1000 * exports.KB;
// Define max file size as 2MB
exports.MAX_UPLOAD_FILE_SIZE = 2 * exports.MB; // 2 Million/Mega Bytes, or 2 MB
exports.LINKS = {
    supportFormLink: 'https://go.gov.sg/formsg-support',
};
